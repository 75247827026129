export const environment = {
    production: true,
    logErrorInConsole: true,
    environmentName: "test",
    version: require("../../package.json").version,
    httpRequestTimeoutSeconds: 120,
    utcDateTimeFormat: "yyyy-MM-dd HH:mm:ss",
    dateTimeFormat: "yyyy-MM-dd HH:mm",
    dateFormat: "yyyy-MM-dd",
    oauthBaseAPI: "https://gsk-event-platform-api-spvevc4hda-ey.a.run.app/api/v1/auth/",
    profileAPI: "https://gsk-event-platform-api-spvevc4hda-ey.a.run.app/api/v1/profile/",
    resetPasswordAPI: "https://gsk-event-platform-api-spvevc4hda-ey.a.run.app/api/v1/resetpassword/",
    usersAPI: "https://gsk-event-platform-api-spvevc4hda-ey.a.run.app/api/v1/admin/users/",
    rolesAPI: "https://gsk-event-platform-api-spvevc4hda-ey.a.run.app/api/v1/admin/roles/",
    organizationsAPI: "https://gsk-event-platform-api-spvevc4hda-ey.a.run.app/api/v1/admin/organizations/",
    languagesAPI: "https://gsk-event-platform-api-spvevc4hda-ey.a.run.app/api/v1/admin/languages/",
    smtpMailAccountsAPI: "https://gsk-event-platform-api-spvevc4hda-ey.a.run.app/api/v1/admin/mailaccounts/",
    mailtemplatesAPI: "https://gsk-event-platform-api-spvevc4hda-ey.a.run.app/api/v1/admin/mailtemplates/",
    registrationConfigAPI: "https://gsk-event-platform-api-spvevc4hda-ey.a.run.app/api/v1/admin/registrationconfig/",
    invitationsAPI: "https://gsk-event-platform-api-spvevc4hda-ey.a.run.app/api/v1/admin/invitations/",
    acceptInvitationsAPI: "https://gsk-event-platform-api-spvevc4hda-ey.a.run.app/api/v1/acceptinvitation/",
    roomsAPI: "https://gsk-event-platform-api-spvevc4hda-ey.a.run.app/api/v1/admin/rooms/",
    eventsAPI: "https://gsk-event-platform-api-spvevc4hda-ey.a.run.app/api/v1/admin/events/",
    participantAccessAPI: "https://gsk-event-platform-api-spvevc4hda-ey.a.run.app/api/v1/admin/eventparticipants/",
};
